//Here goes the data

import img1 from './images/book1.jpg'
import img2 from './images/book2.jpg'
import img3 from './images/book3.jpg'

export const books = [
  {
    id: 1,
    author: 'Jordan Moore',
    title: 'Libro 1 sobre Libro',
    img: img1,
  },
  {
    id: 2,
    author: 'Jean Bejar',
    title: 'Aventuras de la vida',
    img: img2,
  },
  {
    id: 3,
    author: 'Pedro palotes',
    title: 'Libro 3 sobre Libro',
    img: img3,
  },
]
