//Here goes the component

const Book = (props) => {
  //console.log(props);
  const { img, title, author, number } = props

  console.log(number)

  return (
    <article className="book">
      <img src={img} alt={title} />
      <h2>{title}</h2>
      <h4>{author.toUpperCase()}</h4>
      <span className="number">{`# ${number + 1}`}</span>
    </article>
  )
}

export default Book
